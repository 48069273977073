import requestToServer from "./requestToServer";

export default class DataHelper {
  static fetchGetAppointmentInfo(appointmentId) {
    const { epionApiUrl } = DataHelper;
    if (!appointmentId) return null;

    return requestToServer({
      path: `${epionApiUrl}/appointments/${appointmentId}`,
    }).then((response) => response.json());
  }

  static fetchGetPatientInfo(patientId) {
    const { epionApiUrl } = DataHelper;
    if (!patientId) return null;

    return requestToServer({
      path: `${epionApiUrl}/patients/${patientId}`,
    }).then((response) => response.json());
  }

  static getPrevisitStatus(appointmentId) {
    const { epionApiUrl } = DataHelper;
    if (!appointmentId) return null;

    return requestToServer({
      path: `${epionApiUrl}/previsit_status/${appointmentId}`,
    }).then((response) => response.json());
  }

  static getArrivalManagementStatus(forCurrentDepartment) {
    const { epionApiUrl } = DataHelper;

    return requestToServer({
      path: `${epionApiUrl}/arrival_management_status?for_current_department=${forCurrentDepartment}`,
    }).then((response) => response.json());
  }

  static getPatientRegistrations() {
    const { epionApiUrl } = DataHelper;

    return requestToServer({
      path: `${epionApiUrl}/patient_registrations`,
    }).then((response) => response.json());
  }

  static fetchTelehealthAppointments() {
    return requestToServer({ path: `${DataHelper.epionApiUrl}/telehealths` })
      .then((res) => res.json())
      .then((json) => json.data);
  }

  static createProviderPreferences({ username, pin }) {
    const dataToSend = { preferences: { username, pin } };
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/provider_preferences`,
      method: "post",
      body: dataToSend,
    });
  }

  static updateProviderPreferences({ dismissed, username, optIn, mobilePhone }) {
    const dataToSend = {
      preferences: {
        dismissed,
        username,
        optIn,
        mobilePhone,
      },
    };
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/provider_preferences`,
      method: "post",
      body: dataToSend,
    });
  }

  static async fetchPrevisits(epionApiUrl) {
    const res = await requestToServer({ path: `${epionApiUrl}/previsits/new` });
    return res.json();
  }

  static async fetchProviderPreferencesFor() {
    const res = await requestToServer({ path: `${DataHelper.epionApiUrl}/provider_preferences/new` });
    return res.json();
  }

  static fetchUpdateCopay(data, appointmentId) {
    const dataToSend = Object.assign(data, { appointment_id: appointmentId });
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/copay_overrides`,
      method: "post",
      body: dataToSend,
    });
  }

  static sendFormsOnTheFly(data, appointmentId, patientId) {
    const dataToSend = { ...data, appointment_id: appointmentId, patient_id: patientId };
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/forms_on_the_fly`,
      method: "post",
      body: dataToSend,
    });
  }

  static async sendPrevisitInvite(appointmentId) {
    const res = await requestToServer({
      path: `${DataHelper.epionApiUrl}/previsit_invites`,
      method: "post",
      body: { appointment_id: appointmentId },
    });

    return res.json();
  }

  static sendPatientRegistrationLink(patientPhoneNumber) {
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/patient_registrations`,
      method: "post",
      body: { phone_number: patientPhoneNumber },
    });
  }

  static sendPatientSms(message, appointmentId, patientId) {
    const body = {
      appointment_id: appointmentId,
      message,
      patient_id: patientId,
    };

    return requestToServer({
      path: `${DataHelper.epionApiUrl}/patient_sms`,
      method: "post",
      body,
    });
  }

  static async fetchPrevisitDataForInsuranceList(previsitId, epionApiUrl) {
    const res = await requestToServer({ path: `${epionApiUrl}/previsits/${previsitId}.json` });
    return res.json();
  }

  static async fetchTextMessage(sid) {
    const res = await requestToServer({
      path: `${DataHelper.epionApiUrl}/patient_sms/${sid}`,
    });
    return res.json();
  }

  static async fetchTextMessageHistory(patientId) {
    const res = await requestToServer({
      path: `${DataHelper.epionApiUrl}/patient_sms?patient_id=${patientId}`,
    });
    return res.json();
  }

  static async fetchTwoWayMessageHistory(patientId, markAsRead = false) {
    const res = await requestToServer({
      path: `${DataHelper.epionApiUrl}/messages?patient_id=${patientId}&mark_as_read=${markAsRead}`,
    });

    return res.json();
  }

  static async updateMessageThread(messageThreadId, data) {
    const res = await requestToServer({
      path: `${DataHelper.epionApiUrl}/message_threads/${messageThreadId}`,
      body: { message_thread: data },
      method: "PATCH",
    });

    return res.json();
  }

  static async fetchMessageThreads() {
    const res = await requestToServer({
      path: `${DataHelper.epionApiUrl}/message_threads?status=open`,
    });

    return res.json();
  }

  static deleteInsurance(insuranceId) {
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/insurance_uploads/${insuranceId}`,
      method: "delete",
      body: {},
    });
  }

  static updateInsurance(insuranceId, data) {
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/insurance_uploads/${insuranceId}`,
      method: "put",
      body: data,
    });
  }

  static async sendPaymentRequest(data) {
    const dataToSend = {
      payment_request: data,
    };
    const response = await requestToServer({
      path: `${DataHelper.epionApiUrl}/payment_requests`,
      method: "post",
      body: dataToSend,
    });
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    }

    return Promise.reject(responseData);
  }

  static sendRefreshAppointments() {
    return requestToServer({
      path: `${DataHelper.epionApiUrl}/refresh_appointments`,
      method: "post",
      body: {},
    });
  }

  static fetchOutstandingBalanceDataForPatient(patientId) {
    const url = new URL(`${DataHelper.epionApiUrl}/outstanding_balance_info`);
    url.searchParams.append("patient_id", patientId);
    const urlForPatient = url.toString();

    return requestToServer({ path: urlForPatient });
  }

  // departmentId is used to determine if the user has logged in
  static get athenaDepartmentId() {
    const { testAthenaDepartmentId } = DataHelper;
    if (testAthenaDepartmentId) {
      return testAthenaDepartmentId;
    }

    const statusFrame = document.getElementById("Status");

    if (statusFrame?.clientHeight) {
      const departmentIdFromStatus = statusFrame?.contentDocument.getElementById("DEPARTMENTID")?.value?.toString();

      // Some clients don't have department id select in status frame
      return departmentIdFromStatus || "DEFAULT_DEPARTMENT_ID";
    }

    return null;
  }

  static get athenaFrame() {
    return document
      .getElementById("GlobalWrapper")
      ?.contentDocument.getElementById("frameContent")
      .contentDocument.getElementById("frMain");
  }

  static get athenaLoginFrame() {
    return document.getElementById("GlobalWrapper")?.contentDocument.getElementsByName("frHelp")?.[0];
  }

  static get appointmentId() {
    const inlinePageData = JSON.parse(
      DataHelper.athenaFrame?.contentWindow?.document?.getElementById("inline-page-data")?.innerHTML || "{}",
    );

    const findInInlineData = (key) => Object.values(inlinePageData).find((element) => key in element);
    const maybeAppointmentId =
      DataHelper.athenaFrame?.contentWindow?.appointmentid?.value ||
      DataHelper.athenaFrame?.contentWindow?.appointmentid ||
      findInInlineData("appointment")?.ID ||
      findInInlineData("clinical_encounter")?.clinical_encounter?.AppointmentID ||
      findInInlineData("last_visit")?.last_visit?.specialty?.AppointmentID;

    return Number(maybeAppointmentId) || undefined;
  }

  static get patientId() {
    return DataHelper.athenaFrame?.contentDocument?.getElementById("PatientActionMenu_DATA")?.getAttribute("PATIENTID");
  }

  static get epionApiUrl() {
    return getInputValueById("epion-api-url");
  }

  static get epionUserProperties() {
    return JSON.parse(getInputValueById("epion-user-properties"));
  }

  static get epionTelehealthEnabled() {
    return JSON.parse(getInputValueById("epion-telehealth-enabled"));
  }

  static get epionPrevisitEnabledForDepartment() {
    return JSON.parse(getInputValueById("epion-previsit-enabled-for-department"));
  }

  static get epionCopayAllowedForUser() {
    return JSON.parse(getInputValueById("epion-copay-allowed"));
  }

  static get epionOutstandingBalanceAllowedForUser() {
    return JSON.parse(getInputValueById("epion-outstanding-balance-allowed"));
  }

  static get epionTelehealthData() {
    return JSON.parse(getInputValueById("epion-telehealth-data"));
  }

  static get testAthenaDepartmentId() {
    const testAthenaDepartmentIdValue = getInputValueById("epion-test-athena-department-id");
    return testAthenaDepartmentIdValue && JSON.parse(testAthenaDepartmentIdValue);
  }
}

const getInputValueById = (id) => {
  const element = document.getElementById(id);
  return element ? element.value : undefined;
};

